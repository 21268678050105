<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <v-row>
            <v-col cols="8"><v-card-title>Profile</v-card-title></v-col>
            <v-col cols="4">
              <!-- <v-btn
                @click="logout()"
                class="ma-6 mt-6 float-right"
                outlined
                color="red"
                >Logout</v-btn
              > -->
            </v-col>
          </v-row>

          <v-card-subtitle>Preferences</v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="3"
                  ><v-text-field
                    v-model="firstname"
                    :rules="[rules.required]"
                    label="First Name"
                    required
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="3"
                  ><v-text-field
                    v-model="lastname"
                    :rules="[rules.required]"
                    label="Last Name"
                    required
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="3"
                  ><v-text-field
                    v-model="role"
                    label="Role"
                    disabled
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="3"
                  ><v-select
                    v-model="notification"
                    :items="notifications"
                    label="Notifications"
                  ></v-select
                ></v-col>
                <v-col cols="12" md="3"
                  ><v-text-field v-model="phone" label="Phone"></v-text-field>
                </v-col>
                <v-col cols="12" md="3"
                  ><v-switch
                    v-model="$vuetify.theme.dark"
                    inset
                    label="Dark mode"
                  ></v-switch>
                </v-col>
                <v-col cols="6"
                  ><v-btn
                    class="ma-2"
                    outlined
                    color="red"
                    block
                    @click="updateProfile()"
                  >
                    Save
                  </v-btn></v-col
                >
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
export default {
  name: "Profile",
  data: () => ({
    valid: true,
    rules: {
      required: value => !!value || "Required."
    },
    firstname: "",
    lastname: "",
    phone: "",
    notification: "",
    notifications: ["Email Only", "SMS Only", "Both"],
    darkMode: false
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    }
  },
  methods: {
    updateProfile() {
      let vm = this;
      let user = {
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        notification: this.notification,
        darkMode: this.$vuetify.theme.dark
      };
      console.log(user);
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .put(vm.$root.urls.api + "/profile", {
          user
        })
        .then(function(results) {
          // update state
          vm.$store.commit("updateProfile", {
            firstname: vm.firstname,
            lastname: vm.lastname,
            notification: vm.notification,
            phone: vm.phone,
            darkMode: vm.$vuetify.theme.dark
          });
          // vm.$vuetify.theme.dark = vm.darkMode
          // toast
          vm.$toasted.show("Profile updated").goAway(2500);
        });
    },
    logout() {
      localStorage.removeItem("rtbjwt");
      this.$store.commit("login", {
        username: "",
        role: "",
        firstname: "",
        lastname: "",
        // notification: user.notification,
        phone: "",
        darkMode: ""
        // unread: user.unread,
      });
      this.$vuetify.theme.dark = false;
      this.$router.push({
        name: "Login"
      });
    }
  },
  mounted: function() {
    // query state for profile values
    this.firstname = this.$store.state.user.firstname;
    this.lastname = this.$store.state.user.lastname;
    this.phone = this.$store.state.user.phone;
    this.notification = this.$store.state.user.notification;
    this.darkMode = this.$store.state.user.darkMode;
    // $vuetify.theme.dark;
  }
};
</script>
